.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}

.ant-upload-list-picture-card {
    float: none;
}

.ant-upload-list-item-info > span{
    display: block;
    margin: auto;
    width: 88px;
    height: 88px;
}